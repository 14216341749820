import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue } from "bootstrap-vue";
import VueYoutube from "vue-youtube";
import axios from "axios";
import Scrollspy from "vue2-scrollspy";
var VueScrollTo = require("vue-scrollto");
import i18n from "./lang/index.js";
import { installLocale } from "./lang/asyncLang.js";

import "vue-select/dist/vue-select.css";
import "@/assets/scss/style.scss";
import "@/assets/scss/new_design/style.scss";
import "@/assets/scss/new_design/responsive.scss";
import "@/assets/css/materialdesignicons.min.css";
import "vue2-datepicker/index.css";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import VueCurrencyFilter from "vue-currency-filter";
import VueMask from "v-mask";
import { VueMaskDirective } from "v-mask";
import VueSlider from "vue-slider-component";
import VueLazyload from "vue-lazyload";
import "vue-slider-component/theme/default.css";
import "aos/dist/aos.css";

import ApiService from "@/services/api.service";
// global mixin
import useGlobal from "@/globals/useGlobal";

// Register global components
Vue.component("VueSlider", VueSlider);
Vue.mixin(useGlobal);

// Use plugins
Vue.use(VueLazyload);
Vue.use(VueYoutube);
Vue.use(VueMask);
Vue.use(Scrollspy);
Vue.use(VueScrollTo);
Vue.use(VueToast, { position: "top" });
Vue.use(BootstrapVue);
Vue.use(VueCurrencyFilter, {
  symbol: "c",
  thousandsSeparator: " ",
  fractionCount: 0,
  fractionSeparator: ",",
  symbolPosition: "back",
  symbolSpacing: true,
});
Vue.config.productionTip = false;

// Set axios base URL based on environment
const setAxiosBaseURL = () => {
  const url = window.location.href;
  if (url.indexOf("https://kontrakt.edu.uz") > -1) {
    axios.defaults.baseURL = "https://kontrakt-api.edu.uz/";
  } else if (url.indexOf("https://kontrakttest.edu.uz/") > -1) {
    axios.defaults.baseURL = "https://kontrakttest-api.edu.uz/";
  } else if (url.indexOf("http://kontrakttest.edu.uz/") > -1) {
    axios.defaults.baseURL = "http://kontrakt-api.edu.uz/";
  } else if (url.indexOf("http://oldkontrakt.edu.uz/") > -1) {
    axios.defaults.baseURL = "http://kontraktnext-api.edu.uz/";
  } else if (url.indexOf("https://kontraktnext.edu.uz/") > -1) {
    axios.defaults.baseURL = "https://kontraktnext-api.edu.uz/";
  } else if (url.indexOf("https://oldkontrakt.edu.uz/") > -1) {
    axios.defaults.baseURL = "https://kontraktnext-api.edu.uz/";
  } else if (url.indexOf("http://mymyb-prod.apptest.uz/") > -1) {
    axios.defaults.baseURL = "http://mymvb-api.apptest.uz/";
  } else if (url.indexOf("https://mymyb-prod.apptest.uz/") > -1) {
    axios.defaults.baseURL = "https://mymvb-api.apptest.uz/";
  } else {
    axios.defaults.baseURL = "https://kontraktnext-api.edu.uz/";
    axios.defaults.baseURL = "https://kontrakt-api.edu.uz/";
  }
};

setAxiosBaseURL();

// Define request interceptor before using it
const requestInterceptor = (request) => {
  request.withCredentials = true;
  return request;
};
axios.interceptors.request.use((request) => requestInterceptor(request));

// Initialize ApiService interceptor
ApiService.mount401Interceptor();

// Ensure locale is set
if (localStorage.getItem("locale") === null) {
  localStorage.setItem("locale", "uz_latn");
}

// Setup router navigation guard
router.beforeEach((to, from, next) => {
  const newTitle = to.meta.title || "EDU Kontrakt"; // Use the route's meta title or a default if not set
  document.title = newTitle;
  next();
});

// Initialize application
(async () => {
  try {
    await installLocale();
  } catch (e) {
    alert("Could not load locale");
  }

  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
})();

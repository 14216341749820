import i18n from "./index";

export async function installLocale() {
    let oz = {};
    let uz = {};
    let ru = {};

    await fetch(
        "https://opensheet.elk.sh/1cY_rjWEaMWorVpc4ghv9Ke4JYhGlLEYNTEWifNckjRM/2"
    )
        .then((res) => res.json())
        .then(async (data) => {

            await data.forEach((item, index) => {
                oz[item.key] = item.oz ? item.oz : item.key;
                uz[item.key] = item.uz ? item.uz : item.key;
                ru[item.key] = item.ru ? item.ru : item.key;
            });

            i18n.setLocaleMessage("uz_latn", { ...i18n.messages.uz_latn, ...uz });
            i18n.setLocaleMessage("uz_cyrl", { ...i18n.messages.uz_cyrl, ...oz });
            i18n.setLocaleMessage("ru", { ...i18n.messages.ru, ...ru });
        });
}
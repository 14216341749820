import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import uz_cyrl from './translate/uz_cyrl.json'
// import uz_latn from './translate/uz_latn.json'
// import ru from './translate/ru.json'

Vue.use(VueI18n)
const AVAILABLESLOCALES = ['uz_latn', 'ru', 'uz_cyrl']
const locLocale = localStorage.getItem("locale")

const locale = AVAILABLESLOCALES.includes(locLocale) ? locLocale : AVAILABLESLOCALES[0];

const messages = {
  uz_latn: {
  },
  uz_cyrl: {
  },
  ru: {
  },
};

const i18n = new VueI18n({
  locale,
  messages,
});


export default i18n;